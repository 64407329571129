import { ReactComponent as SVGLogoAccedo } from '@/assets/svg/logoAccedo.svg';
import { ReactComponent as SVGLogoAtb } from '@/assets/svg/logoAtb.svg';
import { ReactComponent as SVGLogoAutotrader } from '@/assets/svg/logoAutotrader.svg';
import { ReactComponent as SVGLogoBell } from '@/assets/svg/logoBell.svg';
import { ReactComponent as SVGLogoBmo } from '@/assets/svg/logoBmo.svg';
import { ReactComponent as SVGLogoCibc } from '@/assets/svg/logoCibc.svg';
import { ReactComponent as SVGLogoCox } from '@/assets/svg/logoCox.svg';
import { ReactComponent as SVGLogoIbm } from '@/assets/svg/logoIbm.svg';
import { ReactComponent as SVGLogoRbc } from '@/assets/svg/logoRbc.svg';
import { ReactComponent as SVGLogoRogers } from '@/assets/svg/logoRogers.svg';
import { ReactComponent as SVGLogoToronto } from '@/assets/svg/logoToronto.svg';

import './index.scss';

export const Clients = () => (
  <section className="container section-padding">
    <h2 className="section-title">Clients who trust us</h2>
    <div className="clients">
      <SVGLogoToronto data-index={1} />
      <SVGLogoBell data-index={2} />
      <SVGLogoCox data-index={3} />
      <SVGLogoRogers data-index={4} />
      <SVGLogoIbm data-index={5} />
      <SVGLogoAtb data-index={6} />
      <SVGLogoAccedo data-index={7} />
      <SVGLogoCibc data-index={8} />
      <SVGLogoBmo data-index={9} />
      <SVGLogoRbc data-index={10} />
      <SVGLogoAutotrader data-index={11} />
    </div>
  </section>
);
